import React from 'react';

import './Home.scss';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Logo from '../components/Logo';

export type Props = {
    onStart?: () => void;
};

const Home: React.FC<Props> = ({ onStart }) => {
    return (
        <Layout>
            <Logo />
            <div className="welcome">
                <p>Hey you… welcome to the Swarm Challenge! 🐝</p>
                <p>Cubbit has just recruited you to keep all files safe</p>
            </div>
            <Button onClick={onStart}>Start</Button>
        </Layout>
    );
};

export default Home;
