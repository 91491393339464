import { useEffect, useRef } from 'react';
import Phaser from 'phaser';

import './Renderer.scss';
import { Scene } from './Scene';

// See: http://labs.phaser.io/edit.html?src=src%5Cgames%5Csnake%5Cpart7.js

const gameConfig: Phaser.Types.Core.GameConfig = {
    width: 625,
    height: 625,
    transparent: true,
    scene: [Scene],
    scale: {
        mode: Phaser.Scale.FIT,
    },
};

// eslint-disable-next-line import/prefer-default-export
export const Renderer = () => {
    const game = useRef<Phaser.Game | null>(null);

    useEffect(() => {
        game.current = new Phaser.Game({
            ...gameConfig,
            parent: 'renderer',
        });

        return () => {
            game.current?.destroy(true);
        };
    }, []);

    return <div id="renderer" />;
};
