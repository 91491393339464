/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useState } from 'react';

export const defaultScore = {
    files: 0,
    storage: 0,
    setFiles: (_x: number) => {},
    setStorage: (_x: number) => {},
    reset: () => {},
};

export const ScoreContext = React.createContext(defaultScore);

export const ScoreContextProvider: React.FC = ({ children }) => {
    const [files, setFiles] = useState(0);
    const [storage, setStorage] = useState(0);

    const reset = useCallback(() => {
        setFiles(0);
        setStorage(0);
    }, []);

    return (
        <ScoreContext.Provider
            value={{
                files,
                setFiles: (x) => setFiles(x),
                storage,
                setStorage: (x) => setStorage(x),
                reset,
            }}
        >
            {children}
        </ScoreContext.Provider>
    );
};
