import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import reportWebVitals from './reportWebVitals';
import App from './App';
import settings from './settings';

TagManager.initialize({
    gtmId: settings.gtm_id,
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
