import Phaser from 'phaser';

import { Counter } from './Counter';
import gameEmitter from './emitter';
import { Grid } from './Grid';
import { Representable } from './Representable';

// eslint-disable-next-line import/prefer-default-export
export class Cell extends Representable {
    public constructor(
        scene: Phaser.Scene,
        grid: Grid,
        position: Phaser.Geom.Point,
        private readonly _storageCounter: Counter,
    ) {
        super('cell', scene, grid, position);
    }

    public collect(): void {
        this._storageCounter.reset();

        this.destroy();

        this._grid.removeCellAt(this._position);
        this._grid.clearLocks();

        setTimeout(() => {
            gameEmitter.emit('storage', this._storageCounter.amount);
        }, 0);
    }
}
