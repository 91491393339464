// eslint-disable-next-line import/prefer-default-export
export class Counter {
    public constructor(private _amount: number, private readonly _initialAmount = _amount) {}

    public get amount(): number {
        return this._amount;
    }

    public increase(by: number) {
        this._amount += by;
    }

    public decrease(by: number) {
        this._amount -= by;
    }

    public reset() {
        this._amount = this._initialAmount;
    }
}
