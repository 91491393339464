import React, { useContext, useEffect } from 'react';

import './Game.scss';
import arrowUpUrl from '../assets/arrow_up.png';
import arrowLeftUrl from '../assets/arrow_left.png';
import miniLogo from '../assets/mini-logo.png';
import cubbit from '../assets/cubbit.png';
import arrowDownUrl from '../assets/arrow_down.png';
import arrowRightUrl from '../assets/arrow_right.png';
import Layout from '../components/Layout';
import { Renderer, SnakeDirection } from '../lib';
import gameEmitter from '../lib/emitter';
import remoteControlsEmitter from '../lib/remoteControls';
import { ScoreContext } from '../ScoreContext';

export type Props = {
    onGameOver?: () => void;
};

const controlStyle: React.CSSProperties = {
    fontSize: '2rem',
};

const RemoteControls: React.FC = () => {
    return (
        <div className="remoteControls">
            <div className="remoteControls__row">
                <button style={controlStyle} onClick={() => remoteControlsEmitter.emit('turn', SnakeDirection.UP)}>
                    <img src={arrowUpUrl} alt="Arrow Up" />
                </button>
            </div>
            <div className="remoteControls__row">
                <button style={controlStyle} onClick={() => remoteControlsEmitter.emit('turn', SnakeDirection.LEFT)}>
                    <img src={arrowLeftUrl} alt="Arrow Left" />
                </button>
                <button style={controlStyle} onClick={() => remoteControlsEmitter.emit('turn', SnakeDirection.DOWN)}>
                    <img src={arrowDownUrl} alt="Arrow Down" />
                </button>
                <button style={controlStyle} onClick={() => remoteControlsEmitter.emit('turn', SnakeDirection.RIGHT)}>
                    <img src={arrowRightUrl} alt="Arrow Right" />
                </button>
            </div>
        </div>
    );
};

const Game: React.FC<Props> = (props) => {
    const { files, setFiles, /* storage, */ setStorage, reset } = useContext(ScoreContext);

    useEffect(() => {
        reset();

        const onFile = (quantity: number) => setFiles(quantity);
        const onStorage = (quantity: number) => setStorage(quantity);
        const onGameOver = () => {
            if (!props.onGameOver) return;

            setTimeout(props.onGameOver, 1500);
        };

        gameEmitter.on('file', onFile);
        gameEmitter.on('storage', onStorage);
        gameEmitter.on('gameOver', onGameOver);

        return () => {
            gameEmitter.off('file', onFile);
            gameEmitter.off('storage', onStorage);
            gameEmitter.off('gameOver', onGameOver);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout>
            <div className="scoreboard">
                <h2>Files: {files}</h2>
                {/* <h2>Storage: {storage}GB</h2> */}
                <img src={miniLogo} alt="Mini Logo" />
                <img src={cubbit} alt="Cubbit" />
            </div>
            <div className="game">
                <Renderer />
            </div>
            <RemoteControls />
        </Layout>
    );
};

export default Game;
