import Phaser from 'phaser';

import { Snake, SnakeDirection } from './Snake';
import { Counter } from './Counter';
import { Grid } from './Grid';
import gameEmitter from './emitter';
import remoteControlsEmitter from './remoteControls';

// eslint-disable-next-line import/prefer-default-export
export class Scene extends Phaser.Scene {
    public static ROWS = 25;
    public static COLUMNS = 25;
    public static CELL_SIZE = 25;

    private _grid: Grid | undefined;
    private _storage: Counter | undefined;
    private _files: Counter | undefined;
    private _cursors: Phaser.Types.Input.Keyboard.CursorKeys | undefined;
    private _snake: Snake | undefined;

    preload() {
        this.load.image('lock', `${process.env.PUBLIC_URL}/assets/lock.png`);
        this.load.image('cell', `${process.env.PUBLIC_URL}/assets/cell.png`);
        this.load.image('folder_1x', `${process.env.PUBLIC_URL}/assets/folder_1x.png`);
        this.load.image('folder_3x', `${process.env.PUBLIC_URL}/assets/folder_3x.png`);
        this.load.image('folder_5x', `${process.env.PUBLIC_URL}/assets/folder_5x.png`);
    }

    create() {
        const initialStorage = Scene.COLUMNS * Scene.ROWS;
        const initialFiles = 0;

        this._grid = new Grid(this, Scene.COLUMNS, Scene.ROWS, Scene.CELL_SIZE);
        this._storage = new Counter(initialStorage);
        this._files = new Counter(initialFiles);
        this._cursors = this.input.keyboard.createCursorKeys();
        this._snake = new Snake(this, this._grid, Scene.CELL_SIZE, 5);

        this._grid.spawnFolder(this._storage, this._files);

        setTimeout(() => {
            gameEmitter.emit('file', initialFiles);

            gameEmitter.emit('storage', initialStorage);
        }, 500);

        remoteControlsEmitter.on('turn', (direction) => {
            switch (direction) {
                case SnakeDirection.LEFT:
                    this._snake?.faceLeft();
                    break;
                case SnakeDirection.RIGHT:
                    this._snake?.faceRight();
                    break;
                case SnakeDirection.UP:
                    this._snake?.faceUp();
                    break;
                case SnakeDirection.DOWN:
                    this._snake?.faceDown();
                    break;
                default:
                    break;
            }
        });
    }

    update(time: number, delta: number): void {
        if (!this._grid || !this._snake || !this._cursors) {
            console.warn('scene is inconsistent');

            return;
        }

        if (!this._snake.alive) {
            return;
        }

        if (this._cursors.left.isDown) {
            this._snake.faceLeft();
        } else if (this._cursors.right.isDown) {
            this._snake.faceRight();
        } else if (this._cursors.up.isDown) {
            this._snake.faceUp();
        } else if (this._cursors.down.isDown) {
            this._snake.faceDown();
        }

        this._snake.update(time, delta);
    }
}
