import React, { ButtonHTMLAttributes, useMemo } from 'react';
import './Button.scss';

export type Props = {
    block?: boolean;
    variant?: 'primary' | 'secondary' | 'CTA';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({ children, block, variant, ...props }) => {
    const className = useMemo(() => {
        const classes = ['button'];

        if (block) classes.push('button--block');

        if (variant) classes.push(`button--${variant}`);
        else classes.push('button--primary');

        if (props.className) classes.push(props.className);

        return classes.join(' ');
    }, [block, variant, props.className]);

    return (
        <button className={className} {...props}>
            {children}
        </button>
    );
};

export default Button;
