import { TypedEmitter } from 'tiny-typed-emitter';

interface GameEmitter {
    file: (quantity: number) => void;
    storage: (quantity: number) => void;
    gameOver: () => void;
}

const gameEmitter = new TypedEmitter<GameEmitter>();

export default gameEmitter;
