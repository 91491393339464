import Phaser from 'phaser';

import { Grid } from './Grid';
import { Representable } from './Representable';

// eslint-disable-next-line import/prefer-default-export
export class Lock extends Representable {
    public constructor(scene: Phaser.Scene, grid: Grid, position: Phaser.Geom.Point) {
        super('lock', scene, grid, position);
    }
}
