import { useCallback, useMemo, useState } from 'react';

import { ScoreContextProvider } from './ScoreContext';
import Game from './screens/Game';
import GameOver from './screens/GameOver';
import Home from './screens/Home';
import HowTo from './screens/HowTo';

enum Screen {
    HOME = 'home',
    HOW_TO = 'how_to',
    GAME = 'game',
    GAME_OVER = 'game_over',
}

const App = () => {
    const [screen, setScreen] = useState<Screen>(Screen.HOME);

    const changeScreen = useCallback((toScreen: Screen) => {
        setScreen(toScreen);
    }, []);

    const screenToRender = useMemo(() => {
        switch (screen) {
            case Screen.HOME:
            default:
                return <Home onStart={() => changeScreen(Screen.HOW_TO)} />;
            case Screen.HOW_TO:
                return <HowTo onStart={() => changeScreen(Screen.GAME)} />;
            case Screen.GAME:
                return <Game onGameOver={() => changeScreen(Screen.GAME_OVER)} />;
            case Screen.GAME_OVER:
                return <GameOver onShare={() => console.log('share')} onRetry={() => changeScreen(Screen.GAME)} />;
        }
    }, [changeScreen, screen]);

    return <ScoreContextProvider>{screenToRender}</ScoreContextProvider>;
};

export default App;
