import React from 'react';

import './HowTo.scss';
import Arrows from '../components/Arrows';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Logo from '../components/Logo';

export type Props = {
    onStart?: () => void;
};

const HowTo: React.FC<Props> = ({ onStart }) => {
    return (
        <Layout>
            <Logo />
            <div className="tutorial">
                <Arrows className="tutorial__image" />
                <p>Bee ready :) It’s your time to pick up as many files as you can!</p>
                <p>Just a warning: if you leave the map or collide with your snake’s tail, it will be Game Over.</p>
            </div>
            <Button onClick={onStart}>Start</Button>
        </Layout>
    );
};

export default HowTo;
