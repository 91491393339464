import Phaser from 'phaser';

import { Counter } from './Counter';
import gameEmitter from './emitter';
import { Grid } from './Grid';
import { Representable } from './Representable';

// eslint-disable-next-line import/prefer-default-export
export class Folder extends Representable {
    public constructor(
        scene: Phaser.Scene,
        grid: Grid,
        position: Phaser.Geom.Point,
        private readonly _storageCounter: Counter,
        private readonly _filesCounter: Counter,
        private readonly _multiplier: 1 | 3 | 5 = 1,
    ) {
        super(`folder_${_multiplier}x`, scene, grid, position);
    }

    public collect(): void {
        this._storageCounter.decrease(this._multiplier);
        this._filesCounter.increase(this._multiplier);

        this.destroy();

        this._grid.removeFolderAt(this._position);
        this._grid.spawnLocks(this._multiplier);
        this._grid.spawnFolder(this._storageCounter, this._filesCounter);

        if (Math.random() > 0.95) this._grid.spawnCell(this._storageCounter);

        setTimeout(() => {
            gameEmitter.emit('file', this._filesCounter.amount);
            gameEmitter.emit('storage', this._storageCounter.amount);
        }, 0);
    }
}
