import { TypedEmitter } from 'tiny-typed-emitter';

import { SnakeDirection } from './Snake';

interface RemoteControlsEmitter {
    turn: (direction: SnakeDirection) => void;
}

const remoteControlsEmitter = new TypedEmitter<RemoteControlsEmitter>();

export default remoteControlsEmitter;
