import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';
import Phaser from 'phaser';

import { Grid } from './Grid';
import gameEmitter from './emitter';

export enum SnakeDirection {
    UP = 0,
    DOWN = 1,
    LEFT = 2,
    RIGHT = 3,
}

export class Snake {
    private _head: Phaser.Geom.Point;
    private _body: Phaser.Geom.Point[];
    private _tail: Phaser.Geom.Point;

    private _alive = true;
    private _speed = 170;
    private _moveTime = 0;
    private _heading = SnakeDirection.RIGHT;
    private _direction = this._heading;

    private _graphics: Phaser.GameObjects.Graphics;

    public constructor(
        private readonly _scene: Phaser.Scene,
        private readonly _grid: Grid,
        private readonly _size: number,
        initialLength = 1,
    ) {
        if (initialLength < 1) throw new Error("Snake's initialLength cannot be less than 1");

        const startingPosition = new Phaser.Geom.Point(initialLength - 1, 0);

        this._head = startingPosition;
        this._body = [];
        this._tail = Phaser.Geom.Point.Clone(startingPosition);

        for (let x = startingPosition.x - 1; x >= 0; x--) {
            const part = new Phaser.Geom.Point(x, 0);

            this._body.push(part);

            if (x === 0) this._tail = Phaser.Geom.Point.Clone(part);
        }

        this._graphics = this._scene.add.graphics();
    }

    public get alive(): boolean {
        return this._alive;
    }

    public faceLeft() {
        if ([SnakeDirection.UP, SnakeDirection.DOWN].includes(this._direction)) {
            this._heading = SnakeDirection.LEFT;
        }
    }

    public faceRight() {
        if ([SnakeDirection.UP, SnakeDirection.DOWN].includes(this._direction)) {
            this._heading = SnakeDirection.RIGHT;
        }
    }

    public faceUp() {
        if ([SnakeDirection.LEFT, SnakeDirection.RIGHT].includes(this._direction)) {
            this._heading = SnakeDirection.UP;
        }
    }

    public faceDown() {
        if ([SnakeDirection.LEFT, SnakeDirection.RIGHT].includes(this._direction)) {
            this._heading = SnakeDirection.DOWN;
        }
    }

    public update(time: number, _delta: number): void {
        if (time < this._moveTime) return;

        this.move(time);

        this.updateGrid();

        this.draw();
    }

    public updateGrid(): void {
        this._grid.updateSnake(
            this._body
                .map((part) => ({
                    point: Phaser.Geom.Point.Clone(part),
                }))
                .concat([
                    {
                        point: Phaser.Geom.Point.Clone(this._head),
                    },
                ]),
        );
    }

    public move(time: number): boolean {
        this._direction = this._heading;

        const head = Phaser.Geom.Point.Clone(this._head);
        const body: Snake['_body'] = this._body.map(Phaser.Geom.Point.Clone);

        this._head = this._moveInDirection(head);

        body.unshift(Phaser.Geom.Point.Clone(head));

        this._tail = body.pop() || Phaser.Geom.Point.Clone(head);
        this._body = body;

        if (!this._grid.insideBounds(this._head) || this.selfHit() || this._grid.findLock(this._head)) {
            this._alive = false;

            setTimeout(() => gameEmitter.emit('gameOver'), 0);

            return false;
        }

        const cell = this._grid.findCell(this._head);

        if (cell) cell.collect();
        else {
            const folder = this._grid.findFolder(this._head);

            if (folder) {
                folder.collect();
                this.grow();

                if (Math.random() > 0.8) this._speed -= 5;
            }
        }

        this._moveTime = time + this._speed;

        return true;
    }

    private _moveInDirection(point: Phaser.Geom.Point): Phaser.Geom.Point {
        const pointToMove = Phaser.Geom.Point.Clone(point);

        switch (this._heading) {
            case SnakeDirection.LEFT:
                pointToMove.x -= 1;
                break;

            case SnakeDirection.RIGHT:
                pointToMove.x += 1;
                break;

            case SnakeDirection.UP:
                pointToMove.y -= 1;
                break;

            case SnakeDirection.DOWN:
                pointToMove.y += 1;
                break;
        }

        return pointToMove;
    }

    public draw(): void {
        this._graphics.clear();

        const radius = this._size / 2;

        const colors = tinygradient([tinycolor('#065B8F'), tinycolor('#33B1FF')])
            .rgb(Math.floor((this._body.length + 1) * 1.5))
            .map((step) => parseInt(step.toHex(), 16));

        this._drawHead(radius, colors[0]);
        this._drawEyes();

        if (!this._body.length) return;

        this._drawNeck(radius, colors[0], colors[1]);
        this._drawBody(radius, colors.slice(1));
    }

    private _drawHead(radius: number, color: number) {
        const point = this._grid.normalizePoint(this._head);

        const pointCenter = new Phaser.Geom.Point(point.x + radius, point.y + radius);

        this._graphics.fillStyle(color);
        this._graphics.fillCircle(pointCenter.x, pointCenter.y, radius);
    }

    private _drawEyes() {
        const origin = this._grid.normalizePoint(this._head);

        const lowMultiplier = 4;
        const highMultiplier = 9;

        this._graphics.fillStyle(0xffffff);

        switch (this._heading) {
            case SnakeDirection.LEFT:
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * lowMultiplier,
                    origin.y + (this._size / 16) * lowMultiplier,
                    4,
                    4,
                );
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * lowMultiplier,
                    origin.y + (this._size / 16) * highMultiplier,
                    4,
                    4,
                );
                break;

            case SnakeDirection.RIGHT:
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * highMultiplier,
                    origin.y + (this._size / 16) * lowMultiplier,
                    4,
                    4,
                );
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * highMultiplier,
                    origin.y + (this._size / 16) * highMultiplier,
                    4,
                    4,
                );
                break;

            case SnakeDirection.UP:
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * lowMultiplier,
                    origin.y + (this._size / 16) * lowMultiplier,
                    4,
                    4,
                );
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * highMultiplier,
                    origin.y + (this._size / 16) * lowMultiplier,
                    4,
                    4,
                );
                break;

            case SnakeDirection.DOWN:
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * lowMultiplier,
                    origin.y + (this._size / 16) * highMultiplier,
                    4,
                    4,
                );
                this._graphics.fillRect(
                    origin.x + (this._size / 16) * highMultiplier,
                    origin.y + (this._size / 16) * highMultiplier,
                    4,
                    4,
                );
                break;
        }
    }

    private _drawNeck(radius: number, fromColor: number, toColor: number) {
        const first = this._grid.normalizePoint(this._head);
        const second = this._grid.normalizePoint(this._body[0]);

        const firstCenter = new Phaser.Geom.Point(first.x + radius, first.y + radius);
        const secondCenter = new Phaser.Geom.Point(second.x + radius, second.y + radius);

        this._graphics.lineGradientStyle(this._size, fromColor, toColor, fromColor, toColor, 1);
        this._graphics.lineBetween(firstCenter.x, firstCenter.y, secondCenter.x, secondCenter.y);
    }

    private _drawBody(radius: number, colors: number[]) {
        for (let partIndex = 0; partIndex < this._body.length - 1; partIndex++) {
            const first = this._grid.normalizePoint(this._body[partIndex]);
            const second = this._grid.normalizePoint(this._body[partIndex + 1]);

            const firstCenter = new Phaser.Geom.Point(first.x + radius, first.y + radius);
            const secondCenter = new Phaser.Geom.Point(second.x + radius, second.y + radius);

            this._graphics.fillStyle(colors[partIndex]);
            this._graphics.fillCircle(firstCenter.x, firstCenter.y, radius);

            this._graphics.lineGradientStyle(
                this._size,
                colors[partIndex],
                colors[partIndex + 1],
                colors[partIndex],
                colors[partIndex + 1],
                1,
            );
            this._graphics.lineBetween(firstCenter.x, firstCenter.y, secondCenter.x, secondCenter.y);

            if (partIndex === this._body.length - 2) {
                this._graphics.fillStyle(colors[partIndex + 1]);
                this._graphics.fillCircle(secondCenter.x, secondCenter.y, radius);
            }
        }
    }

    public selfHit(): boolean {
        for (let partIndex = 0; partIndex < this._body.length; partIndex++) {
            const part = this._body[partIndex];

            if (this._head.x === part.x && this._head.y === part.y) return true;
        }

        return false;
    }

    public grow(): void {
        this._body.push(Phaser.Geom.Point.Clone(this._tail));
    }
}
