import React from 'react';
// eslint-disable-next-line import/no-unresolved
import './Layout.scss';

const Layout: React.FC = ({ children }) => (
    <div className="layout">
        <div className="layout__wrapper">{children}</div>
    </div>
);

export default Layout;
