import Phaser from 'phaser';

import { Cell } from './Cell';
import { Counter } from './Counter';
import { Folder } from './Folder';
import { Lock } from './Lock';

export type GridElement = {
    point: Phaser.Geom.Point;
};

export type GridElementWithPayload<T> = GridElement & { payload: T };

export class Grid {
    private _snake: Array<GridElement> = [];
    private _folders: Array<GridElementWithPayload<Folder>> = [];
    private _cells: Array<GridElementWithPayload<Cell>> = [];
    private _locks: Array<GridElementWithPayload<Lock>> = [];

    public constructor(
        private readonly _scene: Phaser.Scene,
        private readonly _columns: number,
        private readonly _rows: number,
        private readonly _cellSize: number,
    ) {
        for (let column = 0; column < this._columns; column++) {
            for (let row = 0; row < this._rows; row++) {
                const alternate = column % 2 === row % 2;

                this._scene.add
                    .rectangle(
                        row * this._cellSize,
                        column * this._cellSize,
                        this._cellSize,
                        this._cellSize,
                        alternate ? 0xc4dcea : 0xbad2e1,
                    )
                    .setOrigin(0);
            }
        }
    }

    public normalizePoint(point: GridElement['point']): Phaser.Geom.Point {
        return new Phaser.Geom.Point(point.x * this._cellSize, point.y * this._cellSize);
    }

    public spawnFolder(storageCounter: Counter, filesCounter: Counter) {
        const point = this.randomAvailablePoint();

        if (!point) throw new Error('Cannot generate random point in Grid');

        const random = Math.random();

        let multplier: Folder['_multiplier'] = 1;
        if (random > 0.9) multplier = 5;
        else if (random > 0.5) multplier = 3;

        this._folders.push({
            point,
            payload: new Folder(this._scene, this, point, storageCounter, filesCounter, multplier),
        });
    }

    public spawnCell(storageCounter: Counter) {
        const point = this.randomAvailablePoint();

        if (!point) throw new Error('Cannot generate random point in Grid');

        this._cells.push({
            point,
            payload: new Cell(this._scene, this, point, storageCounter),
        });
    }

    public spawnLocks(quantity = 1) {
        for (let index = 0; index < quantity; index++) {
            const point = this.randomAvailablePoint();

            if (!point) throw new Error('Cannot generate random point in Grid');

            this._locks.push({
                point,
                payload: new Lock(this._scene, this, point),
            });
        }
    }

    public removeFolderAt(point: GridElement['point']) {
        this._folders = this._folders.filter((folder) => folder.point.x !== point.x || folder.point.y !== point.y);
    }

    public removeCellAt(point: GridElement['point']) {
        this._cells = this._cells.filter((cell) => cell.point.x !== point.x || cell.point.y !== point.y);
    }

    public clearLocks() {
        for (const { payload: lock } of this._locks) {
            lock.destroy();
        }

        this._locks = [];
    }

    public updateSnake(snake: Grid['_snake']) {
        this._snake = snake;
    }

    public insideBounds(point: GridElement['point']) {
        if (point.x >= 0 && point.x < this._columns && point.y >= 0 && point.y < this._rows) return true;

        return false;
    }

    public busyAt(point: GridElement['point']): boolean {
        if (this.findSnake(point)) return true;
        if (this.findFolder(point)) return true;
        if (this.findCell(point)) return true;
        if (this.findLock(point)) return true;

        return false;
    }

    public findSnake({ x, y }: GridElement['point']): boolean {
        for (const snakeCell of this._snake) {
            if (x === snakeCell.point.x && y === snakeCell.point.y) return true;
        }

        return false;
    }

    public findFolder({ x, y }: GridElement['point']): Folder | undefined {
        for (const folderCell of this._folders) {
            if (x === folderCell.point.x && y === folderCell.point.y) return folderCell.payload;
        }

        return undefined;
    }

    public findCell({ x, y }: GridElement['point']): Cell | undefined {
        for (const cellCell of this._cells) {
            if (x === cellCell.point.x && y === cellCell.point.y) return cellCell.payload;
        }

        return undefined;
    }

    public findLock({ x, y }: GridElement['point']): Lock | undefined {
        for (const lockCell of this._locks) {
            if (x === lockCell.point.x && y === lockCell.point.y) return lockCell.payload;
        }

        return undefined;
    }

    public randomAvailablePoint(): GridElement['point'] | undefined {
        const matrix: boolean[][] = [];

        for (let x = 0; x < this._columns; x++) {
            matrix[x] = [];

            for (let y = 0; y < this._rows; y++) {
                matrix[x][y] = true;
            }
        }

        for (const { point } of [...this._snake, ...this._folders, ...this._cells, ...this._locks]) {
            matrix[point.x][point.y] = false;
        }

        const availableCells: Phaser.Geom.Point[] = [];
        for (let x = 0; x < this._columns; x++)
            for (let y = 0; y < this._rows; y++) if (matrix[x][y]) availableCells.push(new Phaser.Geom.Point(x, y));

        if (!availableCells.length) return undefined;

        return Phaser.Math.RND.pick(availableCells);
    }
}
