import React, { useContext } from 'react';

import './GameOver.scss';
import Button from '../components/Button';
import Layout from '../components/Layout';
import Logo from '../components/Logo';
import { ScoreContext } from '../ScoreContext';

export type Props = {
    onShare?: () => void;
    onRetry?: () => void;
};

const GameOver: React.FC<Props> = ({ onRetry }) => {
    const { files } = useContext(ScoreContext);

    return (
        <Layout>
            <Logo />
            <div className="score">
                <p className="score__header">Your score:</p>
                <p className="score__value">{files}</p>
                <p className="score__counter">Saved files</p>
            </div>
            <div className="buttons">
                <Button variant="secondary" block onClick={onRetry}>
                    Replay
                </Button>
            </div>
        </Layout>
    );
};

export default GameOver;
