import Phaser from 'phaser';

import { Grid } from './Grid';

// eslint-disable-next-line import/prefer-default-export
export class Representable {
    protected readonly _image: Phaser.GameObjects.Image;

    public constructor(
        protected readonly _imageName: string,
        protected readonly _scene: Phaser.Scene,
        protected readonly _grid: Grid,
        protected readonly _position: Phaser.Geom.Point,
    ) {
        const realPosition = this._grid.normalizePoint(this._position);

        this._image = this._scene.add.image(realPosition.x, realPosition.y, this._imageName).setOrigin(0);
    }

    public destroy() {
        this._image.destroy();
    }
}
